import React from "react";

const NavToggler = ({ onClick }) => {
  return (
    <div
      onClick={onClick}
      className="w-[45px] h-[40px] z-[100] opacity-70 bg-white border border-gray-300 cursor-pointer fixed hidden justify-center items-center left-[20px] top-[20px] rounded
    max-lg:block"
    >
      <span
        className="h-[2px] w-[18px]  bg-skin-color inline-block relative  left-[10px]
        after:h-[2px] after:w-[18px]  after:bg-skin-color after:absolute after:-top-[6px] after:left-0
        before:h-[2px] before:w-[18px]  before:bg-skin-color before:absolute before:top-[6px] before:left-0"
      ></span>
    </div>
  );
};

export default NavToggler;

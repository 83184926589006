import React from "react";

const SectionTitle = ({ children }) => {
  return (
    <h1
      className="font-extrabold text-5xl relative
  before:h-[4px] before:w-[50px] before:bg-skin-color before:absolute before:top-[100%] before:left-0
  after:h-[4px] after:w-[25px] after:bg-skin-color after:absolute after:top-[120%] after:left-0
"
    >
      {children}
    </h1>
  );
};

export default SectionTitle;

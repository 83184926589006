import React from "react";

export const Logo = () => {
  return (
    <div
      className={`text-black font-bold size text-3xl relative px-[20px] py-[10px]
    before:absolute before:w-5 before:h-5 before:border-b-[5px] before:border-l-[5px] before:border-skin-color before:bottom-0 before:left-0
    after:absolute after:w-5 after:h-5 after:border-t-[5px] after:border-r-[5px] after:border-skin-color after:top-0 after:right-0`}
    >
      <span className=" font-clicker-script text-5xl ">S</span>
      implePort
      <div />
    </div>
  );
};

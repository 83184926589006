import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Collapse,
  Grid,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import React, { ReactElement, ReactHTMLElement, useState } from "react";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
export interface PortfolioItemData {
  title: string;
  content: ReactElement<HTMLElement>;
  image: string;
  link: string;
  date: string;
}

const PortfolioItem = (data: { itemData: PortfolioItemData }) => {
  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(!open);
  };
  return (
    <>
      <ListItemButton onClick={handleClick} className="w-full">
        <ListItemText
          primary={data.itemData.title}
          secondary={data.itemData.date}
        />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit className="w-full">
        <Card>
          <CardMedia
            component="img"
            image={data.itemData.image}
            className="w-[50%]"
          />
          <CardContent>
            <Typography gutterBottom variant="h6" component="div">
              {data.itemData.title}
            </Typography>
            {data.itemData.content}
          </CardContent>
          <CardActions>
            {
              /* <Button>More Details</Button> */
              data.itemData.link && (
                <a href={data.itemData.link} target="_blank" rel="noreferrer">
                  <Button>
                    Demonstration
                    <OpenInNewIcon />
                  </Button>
                </a>
              )
            }
          </CardActions>
        </Card>
      </Collapse>
    </>
  );
};

export default PortfolioItem;

import { Box, List, ListItem, Menu, MenuItem } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Logo } from "../component/Logo.tsx";
import HomeIcon from "@mui/icons-material/Home";
import PersonIcon from "@mui/icons-material/Person";
import Inventory2Icon from "@mui/icons-material/Inventory2";
import { To, useLocation, useNavigate } from "react-router-dom";
import NavToggler from "../component/NavToggler.tsx";
import Home from "../component/Home.tsx";
import About from "../component/about/About.tsx";
import Portfolio from "../component/portfolio/Portfolio.tsx";
const navs = [
  // { title: "Home", icon: <HomeIcon />, path: "/" },
  // { title: "About", icon: <PersonIcon />, path: "/about" },
  // { title: "Services", icon: <FormatListBulletedIcon />, path: "/services" },
  { title: "Portfolio", icon: <Inventory2Icon />, path: "/portfolio" },
  // { title: "Contact", icon: <ContactMailIcon />, path: "/contact" },
];

const HomePage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const handleOnClick = (path: To) => {
    navigate(path);
    setAnchorEl(null);
    switch (path) {
      case "/about":
        if (about) {
          about.scrollIntoView({ behavior: "smooth", block: "start" });
        }
        break;
      case "/portfolio":
        if (portfolio) {
          portfolio.scrollIntoView({ behavior: "smooth", block: "start" });
        }
        break;
      case "/":
      default:
        if (home) {
          home.scrollIntoView({ behavior: "smooth", block: "start" });
        }
        break;
    }
  };
  const [home, setHome] = useState<HTMLDivElement>();
  const [about, setAbout] = useState<HTMLDivElement>();
  const [portfolio, setPortfolio] = useState<HTMLDivElement>();

  useEffect(() => {
    handleOnClick(location.pathname);
  }, [home, about, portfolio]);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (path: string) => {
    setAnchorEl(null);
  };
  return (
    <div
      className="flex w-full min-h-screen font-poppins pl-[270px]
    max-lg:pl-0"
    >
      <div>
        <NavToggler onClick={handleClick} />
      </div>
      <Box
        className="fixed w-[270px] h-full top-0 left-0 sx p-30 flex justify-center items-center bg-white
        max-lg:-left-[270px]"
      >
        <div className=" absolute top-12">
          <Logo />
        </div>

        <List className=" space-y-4">
          {navs.map((title) => (
            <ListItem
              key={"nav_" + title.title}
              onClick={() => handleOnClick(title.path)}
              className={`${
                location.pathname === title.path ? "text-skin-color" : ""
              } cursor-pointer flex justify-center items-center font-semibold text-lg space-x-3 border-b-[1px]  active:text-skin-color border-gray-200`}
            >
              <div>{title.icon}</div>
              <div>{title.title}</div>
            </ListItem>
          ))}
        </List>
      </Box>
      <div className="w-full bg-gray-100">
        {/* <About setRef={setAbout} /> */}
        <Portfolio setRef={setPortfolio} />
      </div>
      <Menu
        className=" absolute"
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {navs.map((title) => (
          <MenuItem
            key={"menu_" + title.title}
            onClick={() => handleOnClick(title.path)}
          >
            {title.title}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default HomePage;

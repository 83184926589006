import SectionTitle from "../SectionTitle.tsx";
import { Chip, Grid, List } from "@mui/material";
import PortfolioItem, { PortfolioItemData } from "./PortfolioItem.tsx";
import Typed from "typed.js";
import React, { useEffect, useRef } from "react";

const portfolioItems: PortfolioItemData[] = [
  {
    title: "Decentralized Exchange and Liquidation Trade Bot",
    content: (
      <div className=" space-x-1 space-y-1">
        <Chip label="Trader: Solidity" />
        <Chip label="Optimization: Inline-assembly" />
        <Chip label="RPC: Private Node" />
        <Chip label="IDE: Remix" />
        <Chip label="Chain: Eth, Polygon, BSC, HECO, Milkomeda..., major EVM chains" />
        <Chip label="Protocol: UniswapV2 & V3, Klayswap and other uniswpaV2 forked, AAVE" />
        <Chip label="Scanner: Python" />
        <Chip label="Intercommunication: Message Queue, RabbitMQ" />
        <Chip label="Database: MySQL" />
      </div>
    ),
    image: "/image/dex-arbitrage.png",
    link: "",
    date: "07/2021 - Present",
  },
  {
    title: "Social Media Website - Ethan Social",
    content: (
      <div className=" space-x-1 space-y-1">
        <Chip label="Frontend: React" />
        <Chip label="Styling: Tailwind CSS" />
        <Chip label="UI: Material-UI" />
        <Chip label="State Management: Redux, Redux Thunk" />
        <Chip label="Page Navigation: React Router" />
        <Chip label="Backend: Spring Boot" />
        <Chip label="API: Spring MVC" />
        <Chip label="Data Persistence: Spring Data JPA, MySQL" />
        <Chip label="Authentication: Spring Security, JWT" />
        <Chip label="Messaging: WebSocket" />
        <Chip label="Content Enrichment: Image and Video Uploading" />
      </div>
    ),
    image: "/image/ethan-social.png",
    link: "https://social.ethanportfolio.site/",
    date: "01/2024",
  },

  {
    title: "File Conversion Website - AnythingConvert",
    content: (
      <div className=" space-x-1 space-y-1">
        <Chip label="Frontend: React" />
        <Chip label="Frontend Framework: Next.js" />
        <Chip label="Host: Firebase" />
        <Chip label="API: FastAPI" />
        <Chip label="Data Persistence: Firetore" />
        <Chip label="Authentication: Firebase Authentication" />
        <Chip
          color="warning"
          label="Remark: The file converting server is down currently"
        />
      </div>
    ),
    image: "/image/file-converting.png",
    link: "https://anythingconvert.com/en",
    date: "10/2023",
  },
  {
    title: "2D Aircraft Shooting Game - Space Escaper",
    content: (
      <div className=" space-x-1 space-y-1">
        <Chip label="Game Engine: Unity" />
        <Chip label="Programming Lang: C#" />
        <Chip label="Graphics: Pixel, Aseprite" />
        <Chip label="Supported Platform: Windows, macOS, Android and iOS" />
      </div>
    ),
    image: "/image/space-escaper.png",
    link: "https://store.steampowered.com/app/2262750/Space_Escaper/",
    date: "01/2023",
  },
  {
    title: "Mobile App for Seeking Tutor - FindTutor",
    content: (
      <div className="space-x-1 space-y-1">
        <Chip label="Frontend: ReactNative" />
        <Chip label="Backend: PHP" />
        <Chip label="Data Persistence: MySQL" />
        <Chip label="Supported Platform: Android and iOS" />
      </div>
    ),
    image: "/image/find-tutor.png",
    link: "https://bitbucket.org/TungGG/tutorseekingapp/src/master/",
    date: "11/2016",
  },
];

const Portfolio = ({ setRef }) => {
  const el = useRef(null);
  useEffect(() => {
    const typed = new Typed(el.current, {
      strings: [
        "Software Developer.",
        "Solidity Developer.",
        "Web Developer.",
        "Full Stack Developer.",
      ],
      typeSpeed: 100,
    });
  }, [el]);
  return (
    <div
      ref={(ref) => setRef(ref)}
      className="flex w-[full]  m-auto min-h-screen p-[50px] max-md:p-6"
    >
      <div className="max-w-[1100px] w-full m-auto space-y-10">
        <div>
          <SectionTitle>Portfolio</SectionTitle>
        </div>
        <div className="">
          <h3 className=" text-3xl my-[15px]">
            Hello, my name is{" "}
            <span className=" font-clicker-script text-5xl text-skin-color font-bold">
              Ethan Ho
            </span>
          </h3>
          <h3 className="text-3xl my-[15px]">
            I am a{" "}
            <span className=" text-skin-color" ref={el}>
              software developer
            </span>
          </h3>
          <p className="text-lg text-gray-500 text-justify">
            With over 5 years of experience in software and web development,
            I've dedicated 2+ years to specializing in Web3/Blockchain
            technologies through hands-on involvement in personal projects. My
            current focus remains centered on the continuous refinement and
            advancement of these projects, showcasing my commitment to staying
            on the cutting edge of technology in the rapidly evolving landscape
            of software development.
          </p>
        </div>
        <div>
          <h2 className="font-medium text-2xl text-gray-900 mb-3">
            My Projects :
          </h2>
          <div>
            <List>
              {portfolioItems.map((portfolioItem) => (
                <PortfolioItem
                  key={"pItme_" + portfolioItem.title}
                  itemData={portfolioItem}
                />
              ))}
            </List>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Portfolio;
